.hero-banner#meet-the-team{
	height: 460px;

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		height: 320px;
	}
}

.meet-the-team-intro{
	padding-top: 90px;
	padding-bottom: 150px;

	.title,
	.content{
		margin: 0;
		text-align: center;
	}

	.title{
		margin-bottom: 20px;
		font-size: 40px;
		font-weight: 300;
		color: $glnpPurple;
	}

	.content{
		max-width: 880px;
		margin: 0 auto;
	}

	.staff{
		margin-top: 50px;

		.staffMember{
			display: flex;
			flex-direction: row;
			margin-bottom: 40px;
			align-items: center;

			@media(max-width: map-get($mq-breakpoints, 'tablet')){
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;
			}
			
			img{
				width: 340px;
				height: 340px;
				flex-shrink: 0;
			}
			
			@media(max-width: map-get($mq-breakpoints, 'phone-l')){
				img{
					width: 100%;
					height: auto;
				}
			}

			.staffMemberDetails{
				padding: 30px 50px;

				.name{
					font-size: 40px;
					font-weight: 300;
					color: $glnpPurple;
					margin: 0;
				}

				.position{
					margin: 0;
					font-size: 20px;
					font-weight: 300;
					color: $glnpPurple;
				}

				.bio{
					margin-top: 40px;
					margin-bottom: 30px;

					p{
						margin: 0;
					}
				}

				.contact{
					display: flex;
					flex-direction: column;

					a{
						color: $glnpRed;
						margin-bottom: 10px;
						display: flex;
						align-items: center;

						&:hover{
							color: $glnpTeal;
						}

						&:last-child{
							margin-bottom: 0;
						}
						
						.icon{
							margin-right: 10px;
						}
					}
				}

				@media(max-width: map-get($mq-breakpoints, 'tablet')){
					padding: 0;
					padding-top: 20px;
					.name{
						font-size: 30px;
					}

					.position{
						font-size: 18px;
					}

					.bio{
						font-size: 16px;
						margin: 20px 0;
					}

					.contact{
						font-size: 16px;
					}
				}
			}

		}
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		padding-top: 40px;
		padding-bottom: 40px;
	}

}