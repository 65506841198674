.hero-banner.no-image#default-page{
	height: 300px;
    background-color: #2f6d7f;
    background-image: url('/images/bg/news-leaves-placholder.png');
    background-size: auto;
    background-position: right 180px bottom -20px;

    .layout-wrapper{
    	align-items: center;
    	justify-content:center;
    }

    .title{
    	font-weight: 300;
	    font-size: 50px;
	    color: #fff;
	    max-width: 875px;
	    width: 100%;
		margin: 0;
		text-align: center;
    }

    @media(max-width: map-get($mq-breakpoints, 'tablet')){
    	background-image: none;
		height: 250px;
		
    	.title{
    		font-size: 40px;
    	}
    }
}

.default{
	.layout-wrapper{
		padding-top: 40px;
		padding-bottom: 80px;
	}
}