.hero-banner#projects-landing{
	height: 580px;
}

.projects{

	&.current{
		background-image: url('/images/bg/related-projects-leaves.png') ;
		background-repeat: no-repeat;
		background-position: right 10% bottom 75px;
	}
	

	&.past{
		background-color: #F5F8F9;

		.layout-wrapper{
			padding-bottom: 130px;
			padding-top: 130px;
			
			@media(max-width: map-get($mq-breakpoints, 'tablet')){
				padding-top: 60px;
				padding-bottom: 60px;
			}

		}
	}

	.layout-wrapper{
		padding-top: 110px;
		padding-bottom: 160px;
		text-align: center;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}
	
	.title{
		font-size: 40px;
		font-weight: 300;
		color: $glnpPurple;
		margin: 0;
		margin-bottom: 30px;
	}

	.content{
		margin-bottom: 85px;

		p{
			margin: 0;
		}

	}

}