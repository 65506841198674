.error-block__status-code{
	text-align: center;
}

.error-block__status-text,
.error-block__information{
	max-width: 80%;
	margin: 20px auto;
	text-align: center;
}

.error-block__back-button{
	margin-bottom: 40px;
	margin-top: 40px;
}

.error-block__wrapper{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}