.hero-banner#news-article{
	height: 580px;
	position: relative;

	&:before{
		background-image: linear-gradient(0deg, rgba($glnpDarkGreen,1) 0%, rgba(0,0,0,0) 99%);
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
	}

	.layout-wrapper{
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 100%;
		position: relative;

		.title{
			font-weight: 300;
			font-size: 50px;
			color: $white;
			max-width: 875px;
			width: 100%;
			margin-bottom: 70px;
			margin-top: 0;
		}
	}

	@media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet')){
		height: 380px;

		.layout-wrapper .title{
			margin-bottom: 40px;
		}
	}

	&.no-image{
		height: 415px;

		background-color: $glnpTeal;
		background-image: url('/images/bg/news-leaves-placholder.png');
		background-size: auto;
    	background-position: right 180px bottom -20px;

		&:before{
			display: none;
		}

		.layout-wrapper{
			align-items: center;
			
			.title{
				margin-bottom: 0;
			}
		}
	}
}

.article-content{
	.layout-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 150px;
		padding-bottom: 160px;
		// align-items: flex-start;
	}
	
	.sidebar{
		max-width: 240px;
		width: 100%;
		position: sticky;
		height: auto;
		align-self: flex-start;
		top: 150px;
		
		.author,
		.date{
			display: flex;
			flex-direction: column;
			border-top: 2px solid $glnpPurple;
			padding: 20px 0 50px;

			color: $glnpPurple;
			font-size: 18px;
			font-weight: 400;

			.article-info{
				font-weight: 700;
			}
		}
	}


	article{
		max-width: 880px;
		width: 100%;
		margin-left: 40px;

		.introduction{
			font-size: 22px;
			font-weight: 600;
			color: $glnpPurple;
		}

		.content{
			font-size: 20px;
			font-weight: 400;
			color: $lightpurple;
			margin-bottom: 60px;
		}

		img{
			width: 100% !important;
			height: auto !important;
		}

		p{
			margin: 0;
			margin-bottom: 40px;
		}

		.categories{
			border-top: 2px solid $glnpPurple;

			.title{
				font-size: 20px;
				color: $glnpPurple;
				font-weight: 700;
				margin-top: 20px;
				margin-bottom: 25px;
			}

			.categories-wrap{
				display: flex;
				flex-wrap: wrap;

				.category{
					padding: 15px;
					font-size: 20px;
					color: $glnpPurple;
					font-weight: 400;
					border: 1px solid $glnpPurple;
					background-image: linear-gradient(120deg, $glnpPurple 0%, $glnpPurple 100%);
					margin-right: 10px;
					margin-bottom: 10px;

					&:hover{
						color: $white;
					}

				}
			}
		}

		@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
			margin-left: 0;
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
		.layout-wrapper{
			flex-direction: column-reverse;
			padding-top: 40px;
			padding-bottom: 0;
		}

		.sidebar{
			margin-top: 30px;
			max-width: 100%;

			.date{
				padding-bottom: 0;
			}
		}
	}
}

.related-articles.articles{
	margin-bottom: 125px;

	.title{
		font-size: 40px;
		font-weight: 300;
		color: $glnpPurple;
		margin: 0;
		margin-bottom: 50px;

	}

	.articles-wrap{
		margin-top: 0;
	}
	
	@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
		margin-bottom: 50px;
	
		.title{
			margin-bottom: 25px;
		}
		
		.articles-wrap{
			display: flex;
			flex-wrap: nowrap;
			overflow-x: scroll;
			overflow-y: hidden;
			justify-content: flex-start;
			margin-bottom: 0;

			.article-tease{
				margin-right: 20px;
				flex-shrink: 0;
				max-width: 360px;
			}
		}
	}
}