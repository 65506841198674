.knowledge-hub-category,
.knowledge-hub-article{
	.layout-wrapper{
		padding: 95px 20px 100px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
	.main{
		max-width: 820px;
		width: 100%;

		.title{
			font-size: 40px;
			color: $glnpPurple;
			font-weight: 300;
			margin: 0;
		}

		.snippet{
			font-size: 24px;
			font-weight: 400;
			color: $lightpurple;
		}

		.articles{
			display: flex;
			flex-direction: column;

			.article{
				font-size: 20px;
				font-weight: 400;
				color: $lightpurple;
				padding: 20px 0;
				border-top: 1px solid $lavender;
			}
		}

		.navigation{
			margin-top: 60px;
		}
	}

	@media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'desktop')){
		.main{
			max-width: 100%;
		}
		.layout-wrapper{
			padding-bottom: 40px;
			padding-top: 40px;
			flex-direction: column;
		}

		.sidebar{
			margin-top: 40px;
		}
	}

}

.search-banner#knowledge-hub-category{
	background-position: right 50px bottom -60px;

	.layout-wrapper{
		padding: 95px 20px;
	}
	.title{
		margin-bottom: 25px;
	}
}