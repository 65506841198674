.projects-wrapper{
	margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(auto-fill, 380px);
	justify-content: center;
	grid-column-gap: 20px;
	grid-row-gap: 30px;

	@media(max-width: map-get($mq-breakpoints, 'phone-l')){
		grid-template-columns: repeat(auto-fill, 100%);
	}

	.project{
		height: 470px;
		position: relative;
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		position: relative;
		overflow: hidden;
		cursor: pointer;

		a{
			text-decoration: none;
		}

		img{
			width: 100%;
		}

		.gradient{
			position: absolute;
			background-image: linear-gradient(0deg, rgba($glnpDarkGreen,1) 0%, rgba(0,0,0,0) 99%);
			width: 100%;
			height: 100%;

			&:before{
				position: absolute;
				width: 100%;
				height: 100%;
				content: '';
				top: 0;
				left: 0;
				background-color: $glnpDarkGreen;
				opacity: 0;
				transition: opacity .2s linear;
			}	
		}

		.meta{
			text-align: left;
			padding: 40px;
			position: relative;
			height: 100%;
			display: flex;
			flex-direction: column;
			overflow-y: hidden;
			transform: translateY(325px);
			transition: transform .3s ease; 

			.title{
				color: $white;
				font-size: 26px;
				font-weight: 300;
				margin: 0;
				height: 65px;
				margin-bottom: 40px;
			}

			.snippet{
				color: $white;
				font-size: 18px;
				font-weight: 400;

				p{
					margin: 0;
				}
			}

			span{
				margin-top: auto;
				
				.icon{
					color: $white;
				}
			}


		}

		&:hover{
			.gradient:before{
				opacity: 1;
			}

			.meta{
				transform: translateY(0px);

				.title{
					margin-bottom: 25px;
				}
			}
		}
	}
}