.results-banner{
	background-color: $lavender;
	background-image: url('/images/bg/news-leaves.png');
	background-repeat: no-repeat;
	background-position: right 140px bottom -10px;
	text-align: center;

	.layout-wrapper{
		padding: 100px 20px ;
	}

	.title{
		font-size: 40px;
		font-weight: 300;
		color: $glnpPurple;
		margin: 0;
		margin-bottom: 40px;
	}

	.subtitle{
		margin: 0;
		font-size: 24px;
		font-weight: 400;

		.search-term{
			font-weight: 700;
			color: $glnpPurple;
		}
	}

	@media (max-width: map-get($mq-breakpoints, 'desktop')){
		background-image: none;
	}
}

.results{
	padding-top: 60px;
	padding-bottom: 150px;

	.result{
		padding-top: 40px;
		padding-bottom: 40px;
		border-bottom: 2px solid $lavender;

		&:last-child{
			border-bottom: none;
		}

		.title{
			margin: 0;
			margin-bottom: 20px;
			a{
				color: $glnpPurple;
				font-size: 30px;
				font-weight: 300;
			}
		}

		.breadcrumb{
			font-size: 16px;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0;
			
			a{
				color: $lightpurple;
				margin-right: 10px;

				&:last-of-type{
					color: $glnpPurple;
				}

				&:hover{
					color: $glnpTeal;
				}
			}

			.icon{
				height: 13px;
				width: 13px;
				color: $glnpPurple;
				margin-right: 10px;
			}
		}
	}

	.navigation{
		.pagination{
			.page-item{
				&:first-child{
					margin-left: 0;
				}
			}
		}
	}
}