.hero-banner#services-lp{
	height: 460px;

	// @media(max-width: map-get($mq-breakpoints, 'tablet')){
	// 	height: 340px;
	// }

	.banner-content{
		.title{
			padding-bottom: 20px;
		}
	}
}

.services-body{
	background-image: linear-gradient( 
		180deg,
		rgba($white, .5) 0%,
		rgba($white, .5) 55%,
		rgba($lavender, .5) 55%,
		rgba($lavender, .5) 100%,
	);

	.layout-wrapper{
		text-align: center;
		padding-top: 80px;
		padding-bottom: 100px;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}

	.title{
		font-weight: 300;
		font-size: 40px;
		color:$glnpPurple;
		margin: 0;
		margin-bottom: 20px;

		p{
			margin: 0;
		}
	}

	.content{
		// p:first-child{
		// 	font-size: 24px;
		// }

		p{
			font-size: 20px;
		}

		margin-bottom: 50px;
	}

	.services-wrap{
		display: grid;
		grid-template-columns: repeat(auto-fill, 380px);
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		justify-content: center;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			grid-template-columns: repeat(auto-fill, 100%);
		}

		.service{
			padding: 40px;
			border: 2px solid $lavender;
			background-color: $white;
			text-align: left;

			.title{
				color: $glnpPurple;
				font-size: 30px;
				font-weight: 300;
			}

			.snippet{
				color: $lightpurple;
				font-size: 20px;
				font-weight: 400;
			}

			.icon{
				width: 28px;
				height: 28px;
				color: $glnpPurple;
				transition: color .2s linear;

				&:hover{
					color: $glnpTeal;
				}
			}
		}
	}
}