// Footer container.
// ---------------------------------------------------------
.layout-footer, .layout-subfooter {
	background: $glnpDarkGreen;
	width: 100%;
	background-repeat: no-repeat;
}

.layout-subfooter{
	border-top: 1px solid $darkslategrey;
}

.layout-subfooter__body{
	margin: 0;
	padding: 25px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	.copyright,
	.copyright a,
	.links,
	.links a{
		color: $silver;
		font-size: 16px;
	}

	.seperator{
		color: $darkslategrey;
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		text-align: left;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		.tjs{
			display: block;
			margin-top: 20px;
		}

		.copyright .seperator{
			display: none;
		}

		.links{
			margin-top: 25px;
		}
	}
}

.layout-footer{
	background-image: url('/images/bg/footer-leaves.png');
	background-position: right 90px bottom -10px;
	
	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		background-image: none;
	}

	.layout-wrapper{
		display: grid;
		grid-template-columns: repeat(auto-fill, 280px);
		grid-column-gap: 20px;
		grid-row-gap: 40px;
		padding: 80px 20px 100px;
		justify-content: center;
	

		.footer-col{
			border-top: 1px solid #879A9E;
			padding-right: 15px;

			.title-wrap{
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			.title{
				color: $white;
				font-size: 24px;
				font-weight: 300;
				margin-top: 30px;
			}

			.link-inner-wrap{
				display: flex;
				flex-direction: column;
				
				.link{
					color: $silver;
					text-decoration: none;
					font-size: 16px;
					margin-bottom: 15px;

					&:hover{
						color: $white;
					}
				}
			}

			.contact-details{
				display: flex;
				flex-direction: column;
				font-size: 16px;

				.address-details{
					margin-bottom: 35px;
				}

				.title{
					font-weight: 700;
					color: $white;
					margin-bottom: 10px;
					margin-top: 0;
					font-size: 16px;
					padding-top: 0;
				}
				.address{
					color: $silver;
					margin-bottom: 15px;
				}

				.directions{
					color: $glnpRed;
					font-weight: 600;
					display: flex;
					align-items: center;
					margin-bottom: 30px;

					.icon{
						margin-left: 10px;
						width: 14px;
						height: 14px;
					}
				}

				.tel{
					margin-bottom: 10px;
				}

				.tel,
				.email{
					color: $white;
					display: flex;
					flex-direction: row;
					font-weight: 700;
					
					a{
						color: $white;
						font-weight: 400;
					}
				}

				span,
				a{
					display: block;
				}
			}
			
			.social{
				.title{
					margin-bottom: 10px;

				}

				.social-media-icons{
					.icon{
						width: 32px;
						height: 32px;
					}

					.twitter{
						color: $twitter;
					}
				}
			}

		}
	}

	.accordion-trigger{
		width: 20px;
		height: 20px;
		position: relative;

		.icon{
			color: $white;
			transition: transform .2s linear;
			transform: rotate(90deg);

		}

		@media(min-width: map-get($mq-breakpoints, 'tablet')){
			display: none;
		}

		&.active{
			.icon{
				transform: rotate(-90deg);
			}
		}
	}
}

@media(max-width: map-get($mq-breakpoints, 'tablet')){
	.layout-footer{
		.layout-wrapper{
			grid-row-gap: 0px;
			padding-bottom: 115px;
			padding-top: 40px;

			grid-template-columns: repeat(auto-fill,100%);

			.footer-col{
				
				&:last-child {	
					border-bottom: 1px solid #879A9E;

					.link-inner-wrap{
						margin-bottom: 0;
					}

					.contact-details{
						margin-bottom: 15px;
					}
				}

				.title{
					margin-top: 30px;
					margin-bottom: 30px;
				}

				.links,
				.contact-details{
					display: none;
				}

				.link-inner-wrap{
					margin-bottom: 15px;
				}
			}
		}
	}

}