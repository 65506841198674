.title-section{
	background-image: linear-gradient( 
		180deg,
		rgba($lavender, .5) 0%,
		rgba($lavender, .5) 68%,
		rgba($white, .5) 68%,
		rgba($white, .5) 100%,
	);

	.layout-wrapper{
		text-align: center;
		padding-top: 70px;

	}

	.title{
		font-size: 40px;
		font-weight: 300;
		color: $glnpPurple;
		margin: 0;
		margin-bottom: 30px;
	}

	.image{
		height: 480px;
		width: 100%;
		background-position: center center;
		background-repeat: no-repeat;

		img{
			width: 100%;
		}
	}

	.subtitle{
		font-size: 24px;
		font-weight: 400;
		color: $lightpurple;
		margin-bottom: 50px;
	}

	&.no-image{
		background-image: none;
		background-color: $lavender;
		
		.layout-wrapper{
			padding-bottom: 70px;
			
			.subtitle{
				margin-bottom: 0;
			}

			.title{
				&:only-child{
					margin-bottom: 0;
				}
			}
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		.title{
			font-size: 30px;
		}
		
		.subtitle{
			font-size: 21px;
		}

		.layout-wrapper{
			padding-top: 50px;
		}

		background-image: linear-gradient( 
			180deg,
			rgba($lavender, .5) 0%,
			rgba($lavender, .5) 75%,
			rgba($white, .5) 75%,
			rgba($white, .5) 100%,
		);

		.image{
			height: 280px;
		}
	}
}

.first-content{
	margin-top: 60px;
	margin-bottom: 60px;

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		margin-bottom: 60px;
		margin-top: 20px;
	}


	.layout-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;


		.content{
			max-width: 880px;
			width: 100%;
			margin-right: 40px;
		}

		.sidebar{
			width: 100%;
		}

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			flex-direction: column;
			.sidebar{
				margin-top: 55px;
			}
		}
	}
}

.gallery-slider{
	position: relative;

	.slider-nav{
		position: absolute;
		top: calc(50% - 30px);
		z-index: 1;
		width: 60px;
		height: 60px;
		background-color: $white;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon{
			width: 28px;
			height: 28px;
			color: $glnpPurple;
			transition: color .15s linear;
		}

		&.next{
			right: 0;
		}

		&.prev{
			left: 0;
		}

		&:hover{
			cursor: pointer;

			.icon{
				color: $glnpTeal;
			}
		}
	}

	.gallery-slider-items{
		img{
			margin-right: 10px;
			margin-left: 10px;
		}
	}
}

.related-projects{
	background-image: url('/images/bg/related-projects-leaves.png');
	background-repeat: no-repeat;
	background-size: auto;
    background-position: right 35px bottom 20px;
	
    @media(max-width: map-get($mq-breakpoints, 'tablet')){
		background-image: none;
	}

	.layout-wrapper{
		padding-top: 120px;
		padding-bottom: 120px;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			padding-top: 60px;
			text-align: center;
			padding-bottom: 60px;
		}

		.title{
			font-size: 40px;
			font-weight: 300;
			color: $glnpPurple;
			margin: 0;
		}
	}
}

