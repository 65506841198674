// Colour palette.
// ---------------------------------------------------------

$white: #fff;

$midnight-gray: #333;

$black: #000;

$tjs-green: #41a728;

$tjs-orange: #ffad2d;

$glnpPurple: #4D1B40;
$glnpTeal: rgb(47,109,127);
$glnpDarkGreen: rgb(16,53,61);
$glnpRed: rgb(219,84,97);
$glnpLightPink: rgb(237,232,236);
$header-hover: rgb(59,118,137);
$silver: #B7C2C5;
$darkslategrey: #405D64;
$twitter: #55ACEE;
$darkgrey: #97B6C0;
$lightpurple: #714966;
$lavender: #EDE8EC;

$breadcrumbParent: #A68D9F;