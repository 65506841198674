.news-intro{
	background-color: $lavender;
	background-image: url('/images/bg/news-leaves.png');
	background-repeat: no-repeat;
	background-position: right 140px bottom  -10px;

	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		background-image: none;
	}
	
	.layout-wrapper{
		text-align: center;
		max-width: 900px;
		padding-top: 90px;
		padding-bottom: 90px;
	}
	
	.title{
		color: $glnpPurple;
		font-weight: 300;
		font-size: 40px;
		margin: 0;
		margin-bottom: 40px;

		&:only-child{
			margin-bottom: 0;
		}

	}

	.content{
		font-size: 24px;
		font-weight: 400;
		
		p{
			margin: 0;
		}
	}
}

.articles{
	padding-top: 50px;

	.filters{
		display: flex;
		flex-direction: row;
		margin-bottom: 50px;
		flex-wrap: wrap;

		@media (max-width: map-get($mq-breakpoints, 'desktop-l')){
			overflow-x: scroll;
		}

		.filter{
			flex-shrink: 0;
			padding: 15px;
			color: $glnpPurple;
			font-size: 18px;
			display: block;
			margin-right: 10px;
			border: 1px solid $glnpPurple;
			margin-bottom: 10px;

			background-image: linear-gradient(120deg, $glnpPurple 0%, $glnpPurple 100%);
			
			sup{
				margin-left: 5px;
			}
		
			&.current,
			&:hover{
				color: $white;
				background-size: 100% 100%;
			}
		}
	}

	.articles-wrap{
		margin-bottom: 100px;

		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		display: grid;
		grid-template-columns: repeat(auto-fill, 360px);
		grid-column-gap: 50px;
		grid-row-gap: 70px;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			grid-template-columns: repeat(auto-fill, 100%);
		}
	}

	.navigation{
		margin-bottom: 130px;
		.pagination{
			justify-content: center;
		}
	}
}

.archive{
	background-color: #EAF0F3;
	padding-top: 130px;
	padding-bottom: 130px;
	
	.layout-wrapper{
		display: flex;	
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.title{
		font-weight: 300;
		font-size: 40px;
		color: $glnpPurple;
		margin: 0;
		margin-bottom: 40px;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			margin-bottom: 30px;
		}
	}

	.content{
		font-size: 20px;

		p{
			margin: 0;
		}	
	}

	.archives-wrapper{
		max-width: 640px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, 310px);
		grid-column-gap: 20px;
		margin-left: 50px;
		justify-content: center;

		.year{
			border-bottom: 1px solid #B8A4B2;
			display: flex;
			align-items: center;
			color: $glnpPurple;
			font-size: 20px;
			font-weight: 600;
			padding-top: 15px;
			padding-bottom: 15px;

			&:hover{
				color: $glnpTeal;
			}

			.icon{
				margin-left: auto;
				width: 15px;
				height: 15px;
			}

		}
	}
	
	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		padding-top: 75px;
		padding-bottom: 75px;

		.layout-wrapper{
			flex-direction: column;
		}

		.archives-wrapper{
			margin-top: 30px;
			margin-left: 0;
			grid-template-columns: repeat(auto-fill, 100%);
		}
	}

}