.contact-intro{
	text-align: center;
	background-color: $lavender;
	background-image: url('/images/bg/news-leaves.png');
	background-repeat: no-repeat;
	background-position: right 130px bottom -10px;

	.layout-wrapper{
		max-width: 940px;
		padding-top: 90px;
		padding-bottom: 90px;
	}

	.title{
		font-size: 40px;
		font-weight: 300;
		color: $glnpPurple;
		margin: 0;
		margin-bottom: 40px;
	}

	.content{
		font-size: 24px;
		margin: 0;
		
		p{
			margin: 0;
		}
	}

	@media(max-width: 1366px){
		background-image: none;
	}

	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		.layout-wrapper{
			padding-top: 40px;
			padding-bottom: 40px;

			.title{
				font-size: 30px;
				margin-bottom: 25px;
			}

			.content{
				p{
					font-size: 18px;
				}
			}
		}
	}
}

.details{

	.layout-wrapper{
		padding-top: 100px;
		padding-bottom: 130px;
		display: flex;	
		flex-direction: row;
		justify-content: space-between;

	}

	.title-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40px;

		h3{
			font-size: 30px;
			color: $glnpPurple;
			font-weight: 300;
			margin: 0;
		}

		.required-field{
			margin: 0;
			font-size: 16px;
			font-weight: 400;
			color: $glnpPurple;
			
			.required{
				color: $glnpRed;
			}
		}
	}

	.form-wrapper{
		max-width: 760px;
		width: 100%;
		margin-right: 40px;

		.fields-grid{
			display: grid;
			grid-template-columns: repeat(auto-fill, 370px);
			grid-column-gap: 20px;
			grid-row-gap: 20px;
		}

		.form-col{
			width: 100%;

			input,
			select, 
			textarea{
				width: 100%;
				border: 1px solid $lavender;
				margin-top: 15px;
				padding-left: 20px;
				padding-right: 20px;
				font-size: 18px;
				color: $lightpurple;
			}

			textarea{
				padding: 20px;
			}

			label{
				font-size: 18px;
				font-weight: 400;
				color: $glnpPurple;

				&.required{
					&::after{
						content: ' *';
						color: $glnpRed;
					}
				}
			}

			select{
				background-image: url('/images/icons/arrow-down.png');
				background-repeat: no-repeat;
				background-position: center right 20px;
			}
		}

		.gdpr{
			.form-col{
				font-size: 16px;
			}
		}
		
		.recaptcha{
			margin-bottom: 40px;
		}

		.submit{
			margin-top: 40px;

			.button{
				max-width: 200px;
			}
		}

		.message{
			margin-top: 30px;
			margin-bottom: 30px;
		}

		.alert{
			margin-top: 30px;
			padding: 15px;
			border: 2px solid transparent;
			border-radius: 5px;

			&.success{
				border-color: $glnpPurple;
			}

			&.fail{
				border-color: $glnpRed;
			}
		}
	}

	.contact-details{
		max-width: 370px;
		width: 100%;

		h3{
			font-size: 30px;
			color: $glnpPurple;
			font-weight: 300;
			margin-top: 0;
		}

		.address{
			font-size: 18px;

			p{
				margin: 0;
			}
			margin-bottom: 30px;
		}

		.directions{
			margin-bottom: 30px;
			color: $glnpRed;
			font-size: 18px;
			font-weight: 700;
			display: flex;
			flex-direction: row;
			align-items: center;

			.icon{
				width: 14px;
				color: $glnpRed;
				height: 14px;
				margin-left: 10px;
			}
		}

		.tel{
			border-top: 1px solid $lavender;
			border-bottom: 1px solid $lavender;
			padding-top: 25px;
			padding-bottom: 25px;
			font-size: 18px;
		}

		.times{
			padding-top: 25px;
			font-size: 18px;
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		.layout-wrapper{
			flex-direction: column-reverse;

			padding-top: 40px;
			padding-bottom: 40px;
		}

		.contact-details{
			max-width: 100%;
			margin-bottom: 30px;

			.times{
				padding-bottom: 25px;
				border-bottom: 1px solid $lavender;
			}
		}

		.form-wrapper{
			max-width: 100%;

			.fields-grid{
				grid-template-columns: repeat(auto-fill, 100%);
			}

			.form-row.submit{
				.button{
					max-width: 100%;
				}
			}

			.form-row.recaptcha{
				.form-col{
					display: flex;
					justify-content: flex-start;
				}
				.g-recaptcha{
					transform: scale(0.9);
				}
			}
		}

		.title-wrapper{
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 20px;

			.required-field{
				margin-top: 10px;
			}
		}
	}
}

.find-us{
	background-color: #EAF0F3;

	.layout-wrapper{
		padding: 130px 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.title{
		font-weight: 300;
		color: $glnpPurple;
		font-size: 30px;
		margin: 0;
		margin-bottom: 30px;
	}

	.instructions{
		max-width: 590px;
		width: 100%;

		.button.directions{
			margin-top: 35px;
			background-color: $glnpTeal;
			max-width: 200px;
			background-image: linear-gradient(120deg, #255a70 0%, #255a70 100%)
		}
	}

	.map{
		max-width: 520px;
		width: 100%;
		background-color: $glnpPurple;

		iframe{
			width: 100%;
			height: 100%;
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		.layout-wrapper{
			flex-direction: column;
			padding-top: 40px;
			padding-bottom: 40px;

			.instructions{
				max-width: 100%;
				.directions{
					max-width: 100%;
				}

				margin-bottom: 30px;
			}

			.map{
				height: 320px;
				max-width: 100%;
			}
		}
	}
}
