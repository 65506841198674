// Interaction effects.
// ---------------------------------------------------------

$selection-color: $glnpRed;
$selection-text-color: contrasting-color($selection-color);

$hover-tint: #1f1f1f;
$hover-duration: .1s;
$hover-timing-function: ease-out;

$focus-color: $tjs-orange;
$focus-box-shadow: $focus-color 0 0 5px;
$focus-duration: $hover-duration;
$focus-property: box-shadow;
$focus-timing-function: $hover-timing-function;
