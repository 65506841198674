.navigation{
	.pagination{
		list-style-type: none;
		display: flex;
		flex-direction: row;
		padding-left: 0;
		align-items: center;

		.icon{
			width: 15px;
			height: 15px;
			color: $glnpTeal;
		}

		.disabled{
			.icon{
				color: #D5E2E6;
			}
		}

		.page-item{
			display: block;

			.page-link{
				width: 40px;
				height: 40px;
				background-color: $lavender;
				border-radius: 10em;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 5px;
				transition: all .2s linear;
			}
			
			&:hover{
				
				.page-link{
					background-color: $glnpTeal;
					color: $white;
				}
			}

			.page-link{
				font-size: 22px;
				font-weight: 600;
				color: $glnpTeal;
				line-height: normal;
			}

			&.active{

				.page-link{
					background-color: $glnpTeal;
					color:$white;
				}
			}

			&.arrow{
				background-color: transparent;
				margin: 0 18px;
				.page-link{
					background-color: transparent;
					margin: 0;
					// padding: 18px;

					.icon{
						width: 15px;
						height: 15px;
					}
				}
			}
		}
	}
}