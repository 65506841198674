.search-banner .knowledge-hub-landing{
	
}

.knowledge-hub-categories{
	.layout-wrapper{
		padding-top: 90px;
		padding-bottom: 160px;
	}
	.title{
		text-align: center;
		color: $glnpPurple;
		font-size: 40px;
		font-weight: 300;
		margin: 0;
		margin-bottom: 65px;
	}

	.categories-wrapper{
		display: grid;
		grid-template-columns: repeat(auto-fill, 380px);
		grid-row-gap: 20px;
		grid-column-gap: 20px;
		justify-content: center;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			grid-template-columns: repeat(auto-fill, 100%);
		}

		.category{
			padding: 45px 40px;
			border: 1px solid $lavender;
			display: flex;
			flex-direction: column;
			text-align: left;

			.category-title{
				font-size: 30px;
				font-weight: 300;
				color: $glnpPurple;
				margin: 0;
				margin-bottom: 20px;
			}

			.articles{
				display: flex;
				flex-direction: column;
				padding-top: 0;
			}

			.article-link{
				color: $lightpurple;
				font-size: 20px;
				font-weight: 400;
				padding: 20px 0;

				border-top: 1px solid $lavender;

				&:first-child{
					border-top: none;
				}
			}

			.all-articles{
				align-self: flex-start;
				font-size: 18px;
				color: $glnpRed;
				font-weight: 700;
				margin-top: 35px;

				&:after{
					background-color: $glnpRed;
					height: 2px;
					bottom: -5px;
				}
			}
		}
	}

	.help-widget{
		background-color: $lavender;
		.inner-content{
			padding: 50px 40px;
			text-align: left;

			.title{
				border-top: 2px solid $glnpPurple;
				text-align: inherit;
				font-size: 24px;
				font-weight: 300;
				margin-bottom: 25px;
				padding-top: 20px;
			}

			p{
				color: $glnpPurple;
				font-size: 18px;
				font-weight: 400;
			}

			.button{
				max-width: 180px;
				margin-top: 50px;
			}
		}
	}
}
