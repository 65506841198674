.button {

    @include button;

    border-radius: 0;
    background-color: $glnpRed;
    text-transform: uppercase;
    color: $white;
    font-weight: 700;
    font-size: 18px;
    align-self: flex-start;
    padding: 15px 30px;
    flex-grow: 0;
    width: 100%;

    background-repeat: no-repeat;
    background-size: 0% 100%;
    background-position: 0 100%;
    transition: all 0.3s linear;
    background-image: linear-gradient(120deg, #c6394e 0%, #c6394e 100%);

    &:hover{
        background-size: 100% 100%;
        color: $white;
    }
}

.button--size-s {
    @include button--size-s;
}

.button--size-l {
    @include button--size-l;
}
