.article-tease{
	position: relative;

	&:nth-child(3n+2){
		
		&:before,
		&:after{
			content: '';
			height: 100%;
			width: 1px;
			display: block;
			position: absolute;
			background-color: $lavender;
			top: 0;
		}
		
		&:before{
			left: -25px;
		}
		&:after{
			right: -25px;
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		
		&:nth-child(3n+2){
			&:before,
			&:after{
				display: none;
			}
		}
		width: 100%;
		
		img{
			width: 100%;
			height: auto;
		}
	}


	.post-title,
	.post-date{
		margin: 0;
	}

	.image{
		margin-bottom: 20px;
	}
	.post-meta{
		max-width: 330px;
		width: 100%;
	}

	.post-title{
		margin-bottom: 20px;
		
		a{
			font-size: 26px;
			color: $glnpPurple;
			font-weight: 300;
		}
	}
	.post-date{
		font-size: 16px;
		font-weight: 400;
		color: $lightpurple;
	}
	
	.categories{
		margin-top: 40px;
		
		a{
			font-size: 18px;
			font-weight: 700;
			color: $glnpRed;
			margin-right: 10px;

			&:after{
				background-color: $glnpRed;
			}
		}
	}

}