.sidebar{
	max-width: 245px;
	width: 100%;

	@media (max-width: map-get($mq-breakpoints, 'tablet')){
    	max-width: 100%;
	}

	.widget{
		padding: 20px 5px 20px 0;
		border-top: 2px solid $glnpPurple;
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;

		.content{
			a{
				text-decoration: none;
				font-weight: 400;
			}
		}

	}

	.title{
		font-size: 24px;
		color: $glnpPurple;
		font-weight: 300;
		margin: 0;
		margin-bottom: 20px;
	}

	.links{
		padding-left: 0;
		list-style-type: none;
		margin: 0;

		.link{
			margin-bottom: 20px;

			a{
				color: $glnpPurple;
				font-size: 20px;
				font-weight: 400;
				display: flex;
				align-items: center;
				transition: all .2s linear;
				text-decoration: none;

				&:hover{
					color: $glnpRed;
				}
			}			

			.icon{
				height: 20px;
				width: 20px;
				margin-right: 10px;
			}

			&.current{
				a{
					font-weight: 700;
				}
			}
		}
	}

	.widget-content{
		color: $glnpPurple;
		font-size: 18px;
		font-weight: 400;
		margin: 0;
	}

	.button{
		margin-top: 35px;
		width: auto;
	}
}