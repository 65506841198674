// Header container.
// ---------------------------------------------------------

//push main body down
.layout-main{
	padding-top: 102px;

	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		padding-top: 82px;
	}
}

.layout-header{
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: $white;
	z-index: 999;
	border-bottom: 2px solid #ede8ec;
}

.layout-header-nav{
	max-width: 1180px;
	width: 100%;
	margin: 0 auto;

	.layout-wrapper{
		padding: 0;
	}
	
	.layout-header-nav__inner{
		display: grid;
		grid-template-columns: 180px 1fr 35px;
		grid-template-rows: 100px;
		grid-template-areas: "logo nav search";
		align-items: center;
		font-weight: 400;

		@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'phone-l')){
			grid-template-columns: 50% 0 50%;
		}

		//IE11
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		     display: flex;
		     justify-content: space-between;
		     align-items: center;
		     position: relative;
		     height: 88px;
		}
	}

	.icon--logos__glnp-logo-full{
		width: 160px;
		height: 55px;
		grid-area: logo;
	}

	@media( max-width: 1180px) {
		.layout-header-nav__inner{
			grid-template-columns: 180px 1fr 55px;
		}

		.layout-header-menu__logo{
			margin-left: 20px;
		}

		.header-search{
			margin-right: 20px;
		}

	}

	.layout-header-nav__menu{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		list-style-type: none;
		padding: 0;
		height: 100%;
		margin: 0;
		grid-area: nav;
		max-width: 830px;
		width: 100%;
		margin-left: auto;
		align-items: center;
		margin-right: 25px;

		.layout-header-menu__item{
			padding-left: 0;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.layout-header-menu__link{
				color: $glnpPurple;
				font-size: 16px;
				text-decoration: none;
				transition: color .2s linear;

				&:hover,
				&.current{
					color: $header-hover;
				}

				&:after{
					background: $header-hover;
					bottom: -5px;
				}

				&.current{
					&:after{
						width: 100%;
					}
				}
			}

			&.has-children{
				position: relative;

				.children{
					position: absolute;
					display: none;
					top: 100px;
					left: 0;
					background-color: $glnpDarkGreen;
					padding: 35px;
					min-width: 300px;
					width: 100%;

					ul{
						list-style-type: none;
						padding: 0;
						li {
							margin: 20px 0;
							font-weight: 300;

							&:first-child{
								margin-top: 0;
							}
							&:last-child{
								margin-bottom: 0;
							}

							a{
								color: $silver;
								text-decoration: none;
								font-size: 18px;

								&:hover{
									color: $white;
								}
							}
						}
					}
				}
				&:hover{
					.children{
						display: block;
					}
				}
			}
		}

		@media(max-width: 1110px){
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.header-search{
		grid-area: search;
		position: relative;

		.icon{
			width: 36px;
			height: 36px;
			padding: 9px;
			border: 1px solid #CABBC6;
			border-radius: 10em;
			color: $glnpPurple;
			overflow: visible;

			&:hover{
				cursor: pointer;
			}
		}

	}

}

// mobile menu
.mobile-menu-trigger{
	grid-area: search;
	display: flex;
	justify-content: flex-end;

	.burger-icon{
		width: 58px;
		height: 58px;
		z-index: 1002;
		display: flex;
		flex-direction: column;
		margin-right: 20px;
		padding: 20px;


		.line{
			height: 2px;
			width: 100%;
			margin: 2px 0;
			transition: all .2s linear;
			transform-origin: left center;
			background-color: $glnpPurple;

			&.animate{
				background-color: $white;
			}

			&.top{
				top: 0;

				&.animate{
					transform: rotate(42deg);
				}
			}

			&.bottom{
				bottom: 0;
				
				&.animate{
					transform: rotate(-42deg);
				}

			}

			&.middle{
				top: calc( 50% - 1px );
				opacity: 1;

				&.animate{
					opacity: 0;
				}
			}
		}
	}

	//IE11
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		position: fixed;
		top: 0;
		right: 0;
		margin-right: 40px;
		margin-top: 10px;
		//for some reason IE11 includes margins in an element's defined width 
		.burger-icon{
			// width: 78px;
			// height: 58px;
			margin-right: 0;
		}
	}
}

.floating-nav{
	display: none;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: $white;
	z-index: 999;
	border-bottom: 2px solid #ede8ec;

	.layout-header-nav__inner{
		grid-template-columns: 1fr 140px 1fr;
		grid-template-areas: 'search logo menu';
		grid-template-rows: 80px;
	}
	
	.layout-header-menu__logo{
		grid-area: logo;
		margin-left: 0;

		.icon{
			max-width: 140px;
		}
	}

	.header-search{
		grid-area: search;
		margin-right: 0;
		margin-left: 20px;
		width: 35px;
		height: 35px;
	}
	
	.mobile-menu-trigger{
		grid-area: menu;
	}

}

.mobile-menu{
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1001;
	top: 0;
	left: 0;
	background-color: $glnpPurple;
	overflow-y: scroll;

	.links{
		max-width: 75%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 20% auto;
		margin-top: 80px;
		list-style-type: none;
		padding-left: 0;

		//IE11
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			margin: 10% auto;
		}

		.link{
			

			// account for really small phone i.e iPhone 5
			@media (min-width: map-get($mq-breakpoints, 'phone')) and (max-width: 400px){
				font-size: 20px;
				margin-bottom: 20px;
			}
		}

		.layout-header-menu__item{
			font-size: 24px;
			text-decoration: none;
			width: 100%;
			padding:0;
			margin-bottom: 30px;

			
			a{
				color: $white;
			}

			&.has-children{
				.parent-wrapper{
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.children-trigger{
						width: 22px;
						height: 22px;
						position: relative;

						@media(min-width: map-get($mq-breakpoints, 'desktop')){
							display: none;
						}

						.icon{
							width: 20px;
							height: 20px;
							color: $white;
							opacity: .6;
							transition: all .2s linear;
						}

						&.active{
							.icon{
								transform: rotate(90deg);
								opacity: 1;
							}
						}
					}
				}

				.children{
					display: none;
					ul{
						padding-top: 40px;
						font-size: 21px;
						list-style-type:none;
						padding-left: 0;

						li{
							padding:0;
							margin-bottom: 20px;
							opacity: .7;
						}
					}
				}
			}
		}
	}
}

@media(max-width: map-get($mq-breakpoints, "desktop") ){
	.layout-header{
		display: none;
	}

	.floating-nav{
		display: block;
	}
}

.fullscreen-search{
	display: block;
	height: 0;
	position: fixed;
	width: 100%;
	max-height: 320px;
	z-index: 1001;
	top: 0;
	left: 0;
	background-color: $glnpPurple;
	transition: height .4s ease-in-out;
	overflow: hidden;

	&.visible{
		height: 100%;
	}

	.layout-wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		align-items: flex-end;
		height: 100%;
		flex-direction: column;
	}

	.search{
		width: 100%;

		input{
			border: none;
			background: none;
			font-size: 34px;
			color: $white;
			text-align: left;
			padding: 20px 0;
			width: 100%;
			font-weight: 300;
			border-bottom: 2px solid $lightpurple;

			@media(max-width: map-get($mq-breakpoints, 'tablet')){
				font-size: 22px;
			}

			&:focus{
				outline: none;
			}

			@mixin search_placeholder{
				color: $white;
				font-weight: 300;
				font-size: 34px;
				opacity: 1;
			}

			@mixin search_placeholder_mobile{
				font-size: 22px;
			}

			&::-webkit-input-placeholder{
				@include search_placeholder;
				@media(max-width: map-get($mq-breakpoints, 'tablet')){
					@include search_placeholder_mobile;
				}

			}
			&::-moz-placeholder{
				@include search_placeholder;
				@media(max-width: map-get($mq-breakpoints, 'tablet')){
					@include search_placeholder_mobile;
				}
			}

			&:-ms-input-placeholder{
				@include search_placeholder;
				@media(max-width: map-get($mq-breakpoints, 'tablet')){
					@include search_placeholder_mobile;
				}
			}

			&::-ms-input-placeholder{
				@include search_placeholder;
				@media(max-width: map-get($mq-breakpoints, 'tablet')){
					@include search_placeholder_mobile;
				}
			}

			&::placeholder{
				@include search_placeholder;
				@media(max-width: map-get($mq-breakpoints, 'tablet')){
					@include search_placeholder_mobile;
				}
			}

		}
	}

	.exit{
		background-color: #5F3253;
		line-height: 100%;
		border-radius: 10em;
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon{
			color: #DBD1D9;
			height: 18px;
			width: 18px;
		}

		&:hover{
			cursor: pointer;
		}
	}

	.submit.mobile{
		display: none;
	}

	@media(	max-width: map-get($mq-breakpoints, 'tablet-l')){
		max-height: 100%;

		.layout-wrapper{
			justify-content: flex-start;
			margin-top: 20px;
		}

		.submit.mobile{
			display: block;
			border-bottom: 2px solid $white;
			font-size: 18px;
			font-weight: 700;
			padding-bottom: 5px;
			width: auto;
		}
	}

}