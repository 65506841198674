#about-landing{
	height: 580px;

	@media (max-width:map-get($mq-breakpoints, 'tablet')){
		height: 320px;
	}


	.banner-content{
		@media (max-width:map-get($mq-breakpoints, 'tablet')){
			margin-bottom: -5rem;
			background-color:rgba($glnpPurple, 1);
			padding: 20px;

			.title{
				font-size: 26px;
				padding-bottom: 0;
			}

			.subtitle{
				font-size: 18px;
			}
		}
	}
}

.intro-bar{
	background-color: $lavender;
	background-image: url('/images/bg/news-leaves.png');
	background-repeat: no-repeat;
	background-position: right 130px bottom -10px;
	padding-top: 100px;
	padding-bottom: 100px;

	@media(max-width: 1366px){
		background-image: none;
	}

	.content{
		max-width: 890px;
		font-size: 35px;
		font-weight: 300;
		color:$glnpPurple;
		margin: 0 auto;
		text-align: center;

		p{
			margin: 0;
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		padding-top: 7rem;
		padding-bottom: 40px;

		.content p{
			font-size: 21px;
		}
	}
}

.about-content{
	.layout-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 130px;
		padding-bottom: 60px;
	}

	.content{
		max-width: 650px;

		p:first-child{
			font-size: 22px;
			margin-top: 0;
			
			@media(max-width: map-get($mq-breakpoints, 'tablet')){
				font-size: 19px;
			}
		}
	}

	.col{
		img{
			@media(max-width: map-get($mq-breakpoints, 'tablet')){
				width: 100%;
			}
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'desktop')){
		.layout-wrapper{
			flex-wrap: wrap;
			padding-top: 60px;
		}

		.content{
			max-width: 100%;
		}

		.col:nth-child(2){
			margin: 40px auto 0;
		}
	}
}

.what-we-do{
	text-align: center;
	padding-top: 60px;
	padding-bottom: 140px;

	.title{
		font-size: 40px;
		font-weight: 300;
		margin: 0;
	}

	.content{
		font-size: 24px;
		max-width: 1000px;
		margin: 0 auto;
	}

	.col-wrapper{
		display: grid;
		grid-template-columns: repeat(auto-fill, 580px);
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		justify-content: center;
		margin-top: 20px;

		@media(max-width: map-get($mq-breakpoints, 'desktop')){
			grid-template-columns: repeat(auto-fill, 100%);

			margin-top: 30px;
		}

		.col{
			width: 100%;
			padding: 40px;
			border: 2px solid $lavender;
			text-align: left;
			
			.title{
				font-size: 30px;
				margin-bottom: 10px;
			}

			.content{
				ul{
					margin-bottom: 0;

					li:last-child{
						margin-bottom: 0;
					}
				}

				a{
					text-decoration: none;
				}
			}

		}

	}
}

.explore-glnp{
	background-color: $lavender;

	.layout-wrapper{
		padding-top: 125px;
		padding-bottom: 125px;
		display: flex;
		flex-direction: row;

		.intro{
			margin-right: 30px;
			max-width: 380px;
			background-image:none;

			.title{
				font-size: 40px;
				font-weight: 300;
				margin: 0;
				margin-bottom: 10px;
			}

			.content{
			}
		}

		.col-wrapper{
			display: grid;
			grid-template-columns: repeat(auto-fill, 380px);
			grid-column-gap: 20px;
			grid-row-gap: 20px;
			max-width: 780px;
			justify-content: center;

			.col{
				background-color: $white;
				padding: 40px;
				max-height: 330px;
				height: 100%;

				.title{
					font-size: 30px;
					font-weight: 300;
					margin: 0;
					margin-bottom: 10px;
				}

				.content{
					margin: 0;
				}

				.icon{
					width: 28px;
					height: 28px;
					color: $glnpPurple;
					margin-top: 50px;
				}
			}

			a{
				color: inherit;
				text-decoration: none;
			}
		}
		

		@media(max-width: map-get($mq-breakpoints, 'desktop-l')){
			flex-wrap: wrap;
			padding-top: 60px;
			padding-bottom: 60px;

			.intro{
				max-width: 100%;
				margin: 0 auto;
				text-align: center;
			}

			.col-wrapper{
				margin: 40px auto 0;
				max-width: 100%;
			}
		}

		@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
			padding-top: 50px;

			.title{
				font-size: 30px;
			}

			.content{
				margin-bottom: 0;
			}

			.col-wrapper{
				.col{
					padding: 40px 20px;

					.title{
						font-size: 26px;
						margin-bottom: 15px;
					}

					.icon{
						margin-top: 35px;
					}
				}
			}
		}

		@media(max-width: map-get($mq-breakpoints, 'phone-l')){
			.col-wrapper{
				grid-template-columns: repeat(auto-fill, 100%);
			}
		}
	}
}

.call-to-action-bar#about{
	.layout-wrapper{
		max-width: 1220px;
		justify-content: space-between;
	}
}