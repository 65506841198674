.knowledge-hub-article{
	.layout-wrapper{
		padding-bottom: 160px;
		
		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			padding-bottom: 20px;
		}
	}
	.article{
		margin-top: 40px;
		margin-right: 40px;

		p{
			font-size: 20px;
			color: $lightpurple;

			&:first-child{
				font-size: 22px;
				color: $glnpPurple;
				margin-bottom: 20px;
				font-weight: 600;
				margin-top: 0;
			}

			img{
				margin-top: 40px;
				margin-bottom: 40px;
				width: 100% !important;
				height: auto !important;
			}

			&:last-child{
				img{
					margin-bottom: 0;
				}
			}

		}

		table{
			p{
				font-weight: 400;

				&:first-child{
					font-weight: 400;
				}
			}
		}
	}

	.downloads{
		margin-top: 60px;
		margin-bottom: 50px;
		
		.title{
			font-size: 34px;
			margin-bottom: 20px;
		}

		.files{
			width: 100%;
			border-collapse: collapse;

			.file{
				font-weight: 400;
				color: $lightpurple;
				border-bottom: 1px solid $lavender;

				td{
					padding: 20px 0;
				}


				.typeSize,
				a .icon{
					color: $glnpPurple;
				} 

				.icon{
					margin-right: 10px;
				}

				&:last-child{
					border-bottom: none;
				}

				.typeSize{
					text-transform: uppercase;
					font-weight: 600;
				}

			}
		}

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			margin-bottom: 0;

			.files{
				.file{
					display: flex;
					flex-direction: column;
					font-size: 18px;
					padding: 20px 0;
						
					td{
						padding: 0;
					}

					.filename{
						max-width: 100%;
						width: 100%;
						margin-bottom: 10px;
					}

					.date{
						margin-bottom: 30px;
					}
				}
			}
		}
	}

	.relatedContent{
		border-top: 2px solid $glnpPurple;
		background-color: #EAF0F3;
		padding: 40px;

		p{
			margin: 0;
			color: $lightpurple;
			font-weight: 400;
			font-size: 20px;
		}

		h1,h2,h3,h4,h5,h6{
			font-weight: 300;
			font-size: 34px;
			color: $glnpPurple;
			margin-top: 0;
		}

		a{
			color: $glnpTeal;
			margin-bottom: 20px;
			display: block;
			width: fit-content;
			background-repeat: no-repeat;
			background-size: 100% 0em;
			background-position: 0 100%;
			transition: all 0.2s ease-in;
			background-image: linear-gradient(120deg, $glnpTeal 0%, $glnpTeal 100%);
			border-bottom: 2px solid $glnpTeal;
			
			&:hover{
				background-size: 100% 100%;
				color: $white;
			}
		}
	}

	.references#knowledge-hub-article{
		.layout-wrapper{
			padding-top: 40px;
			padding-bottom: 40px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}