// Viewport declaration.
// ---------------------------------------------------------

@-ms-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

// Global overflow hidden
// ---------------------------------------------------------
html {
}

// Global typography.
// ---------------------------------------------------------
body {
    color: $lightpurple;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    min-width: 320px;
    overflow-x: hidden;

    &.mobile-menu-open{
        overflow-y: hidden;
    }
}

// Global `border-box` sizing.
// ---------------------------------------------------------
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

// Text-selection style.
// ---------------------------------------------------------

::selection {
    background: rgba($selection-color, .9);
    color: contrasting-color($selection-color);
    text-shadow: none;
}

// Reset anchor styles.
// ---------------------------------------------------------

a {
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: $hover-duration;
    transition-timing-function: $hover-timing-function;
    text-decoration: none;

    color: $glnpPurple;
}

// Reset <address> styles.
// ---------------------------------------------------------

address {
    display: block;
    font-style: inherit;
}

// Remove baseline gap from inline-block elements.
// ---------------------------------------------------------

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: top;
}

// Touch interaction.
// ---------------------------------------------------------

a,
label,
button {
    touch-action: manipulation;
}


.layout-wrapper{
    max-width: 1220px;
    padding: 0 20px;
    margin: 0 auto;
}

.breadcrumbs{
    background-color: $glnpPurple;
    
    .layout-wrapper{
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 20px;

        .crumb{
            font-size: 14px;
            color: $breadcrumbParent;
            font-weight: 300;
            margin-right: 10px;
            transition: color .2s linear;
            height: 20px;
            text-decoration: none;
            
            &:last-child{
                color: $white;
            }

            &:hover{
                color: $white;
            }
        }

        .icon{
            color: $white;
            width: 10px;
            height: 10px;
            margin-right: 10px;
        }
    }

    &.mobile{
        display: none;
    }

    @media(max-width: map-get($mq-breakpoints, 'tablet')){
        display: none;
        
        &.mobile{
            display: block;
        }

        .layout-wrapper{
            .crumb{
                height: auto;
            }
        }
    }

}

.search-banner{
    background-color: $glnpTeal;
    background-image: url('/images/bg/knowledge-hub-leaves.png');
    background-repeat: no-repeat;
    background-position: right 50px bottom -30px;

    .layout-wrapper{
        text-align: center;
        padding: 130px 20px;

        @media(max-width: map-get($mq-breakpoints, 'tablet')){
            padding: 50px 20px;
        }


        .title{
            font-size: 40px;
            font-weight: 300;
            color: $white;
            margin: 0;
            margin-bottom: 15px;
            
            @media(max-width: map-get($mq-breakpoints, 'tablet')){
                font-size: 30px;
            }

        }

        .subtitle{
            font-size: 24px;
            font-weight: 400;
            color: $darkgrey;
            margin: 0;
            margin-bottom: 40px;

            @media(max-width: map-get($mq-breakpoints, 'tablet')){
                font-size: 18px;
            }

            a{
                color: $glnpPurple;
                font-weight: 600;
            }
        }

        form{
            display: flex;
            flex-direction: row;
            max-width: 980px;
            width: 100%;
            margin: 0 auto;
            align-items: center;
            justify-content: center;

            .search{
                padding: 30px 40px;
                font-size: 22px;
                font-weight: 400;
                color: $darkgrey;
                border: none;
                border-right: 1px solid #EAF0F3;
                max-width: 870px;
                width: 100%;

                &:focus{
                    box-shadow: none;
                }

                @media(max-width: map-get($mq-breakpoints, 'tablet')){
                    font-size: 20px;
                }
            }

            .submit-wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 105px;
                width: 100%;
                height: 60px;
                background-color: $white;
                position: relative;

                &:hover{
                    cursor: pointer;
                }


                input{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top:0;
                    left:0;
                    background-color: transparent;
                    border: none;
                    z-index: 1;

                    &:hover{
                        cursor: pointer;
                    }
                }
                span{
                    display: block;
                    z-index: 0;
                }

                .icon{
                    width: 26px;
                    height: 26px;
                    color: $glnpTeal;
                }
            }

            @media(max-width: map-get($mq-breakpoints, 'tablet')){
                .submit-wrapper{
                    max-width: 20%;
                    height: 61px;
                }

                .search{
                    border-right: none;
                    border-bottom: 1px solid #EAF0F3;
                    padding: 30px 20px;
                }
            }

        }
    }

    @media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'desktop')){
        background-image: none;

        .layout-wrapper{
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
}

.hero-banner, 
.slider-preview,
.home-hero{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .item{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;        
    }

    .layout-wrapper{
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    .banner-content{
        padding: 40px;
        max-width: 580px;
        width: 100%;

        p{
            margin: 0;
        }

        .title{
            color: $white;
            margin: 0;
            font-size: 40px;
            font-weight: 300;
            padding-bottom: 50px;
            font-family: $font-family;
        }
        .subtitle{
            color: #B8A4B2;
            margin: 0;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
            font-family: $font-family;

        }

        .link{
            font-weight: 700;
            font-size: 18px;
            color: $white;
            background-image: linear-gradient(120deg, $white 0%, $white 100%);    
            border-bottom: 2px solid $white;
            font-family: $font-family;

            &:hover{
                color: $glnpTeal;
            }
        }
        
        &.translucent{
            background-color: rgba($glnpPurple, .8)
        }

        &.opaque{
            background-color: rgba($glnpPurple, 1)
        }

        @media(max-width: map-get($mq-breakpoints, 'tablet')){
            padding-bottom: 20px;
            padding-top: 20px;

            .title{
                font-size: 32px;
            }
            .subtitle{
                font-size: 18px;
            }
        }
    }
}

.layout-wrapper{
    .content,
    .article{
        display: flex;
        flex-direction: column;

        strong{
            font-weight: 600;
            color: $glnpPurple;
        }

        ul{
            list-style-type: none;
            padding-left: 0;
            margin: 20px 0;

            li{
                background-image: url('/images/icons/right-arrow-purple.png');
                background-repeat: no-repeat;
                background-position: center left;
                background-position-y: 2px;
                padding-left: 35px;
                font-size: 20px;
                font-weight: 400;
                color: $glnpPurple;
                margin-bottom: 20px;

                &.current{
                    font-weight: 700;
                }

            }
        }

        ol{
            counter-reset: glnpCounter;
            list-style: none;
            padding-left: 0;

            li{
                counter-increment: glnpCounter;
                position: relative;
                margin-bottom: 25px;

                &:before{
                    content: counter(glnpCounter);
                    color: $white;
                    font-weight: 700;
                    font-size: 18px;
                    background-color: $glnpTeal;
                    padding: 8px 15px;
                    border-radius: 10em;
                    margin-right: 20px;
                }

                ol{
                    padding-left: 40px;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    li{
                        &:before{
                            color: $glnpTeal;
                            background-color: transparent;
                            border: 1px solid $glnpTeal;
                        }
                    }
                }

            }
        }

        blockquote{
            border: 2px solid $glnpPurple;
            font-size: 20px;
            padding: 40px;
            margin: 0;
            position: relative;
            margin-top: 30px;
            margin-bottom: 30px;

            p{
                color: $glnpPurple;
                margin: 0;
                font-weight: 700;
            }

            &:before{
                background-image: url('/images/icons/blockquote-open.png');
                top: -30px;
                left: -10px;
            }
            &:after{
                background-image: url('/images/icons/blockquote-close.png');
                bottom: -30px;
                right: -10px;
            }

            &:before, 
            &:after{
                content: '';
                display: block;
                width: 60px;
                height: 45px;
                background-repeat: no-repeat;
                position: absolute;

            }

            &+p{
                margin-top: 0;
                color: $glnpPurple;
                font-weight: 300;
            }
        }

        hr{
            border-top: 2px solid $glnpPurple;
            width: 100%;
            margin: 0;
            margin-bottom: 20px;

            &+*{
                font-weight: 700;
                font-size: 20px;
                color: $glnpPurple;
                margin-bottom: 0;
            }
        }

        a{
            color: $glnpPurple;
            font-weight: 600;
            text-decoration: underline;
        }

        h1,h2,h3,h4,h5,h6{
            font-weight: 300;
            color: $glnpPurple;
            margin: 0;
        }

        p{
            margin-top: 20px;
            margin-bottom: 20px;

            @media(max-width: map-get($mq-breakpoints, 'tablet')){
                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 16px;
            }
        }

        table{
            border: none;
            display: block;
            border-spacing: 0;
            max-width: 100%;
            margin-bottom: 20px;
            
            tr{
                td,
                th{
                    padding: 10px;
                }

                td{
                    border:none;
                }

                th{
                    border: none;
                    border-bottom: 2px solid $glnpPurple;
                }
            }

            tbody{
                tr{
                    &:nth-child(odd){
                        background-color: rgba($lightpurple, 0.1);
                    }
                }
            }
        }

        @media(max-width: map-get($mq-breakpoints, 'tablet')){
            ul, 
            ol{
                li{
                    font-size: 16px;
                }
            }

            table{
                font-size: 16px;
                overflow-x: scroll;
            }
        }
    }

    .subtitle{
        a{
            text-decoration: underline;
        }
    }

}


.secondary-content{
    background-color: #F5F8F9;
    
    .layout-wrapper{
        padding-top: 150px;
        padding-bottom: 150px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .content{
        max-width: 660px;
        width: 100%;
        margin-right: 40px;
        
        h1,h2,h3,h4,h5,h6{
            font-size: 40px;

            @media(max-width: map-get($mq-breakpoints, 'desktop')){
                font-size: 30px;
            }

        }
    }

    @media(max-width: map-get($mq-breakpoints, 'tablet-l')){
        .image{
            margin-top: 20px;
            margin: 20px auto 0;
        }
        .layout-wrapper{
            padding-top: 70px;
            padding-bottom: 75px;
            justify-content: center;
            flex-direction: column;
        }
    }

    @media(max-width: map-get($mq-breakpoints, 'tablet')){
        .image{
            img{
                width: 100%;
            }
        }
    }

}

.tertiary-content{

    .layout-wrapper{
        background-image: url('/images/bg/content-leaves.png');
        background-repeat: no-repeat;
        background-position: right 60px bottom -15px;
        padding-top: 130px;
        padding-bottom: 100px;
    }

    .content{
        max-width: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;

        h1,h2,h3,h4,h5,h6{
            font-size: 40px;
            margin-bottom: 20px;
        }
    }
    
    @media(max-width: map-get($mq-breakpoints, 'tablet')){
        .layout-wrapper{
            background-image: none;
            padding-top: 60px;
            padding-bottom: 20px;
        }
    }

}

.references{
    &.top-padding{
        padding-top: 100px;
    }

    .layout-wrapper{
        padding: 0 20px 160px;

        .inner-content{
            border-top: 2px solid $glnpPurple;
            background-color: #EAF0F3;
            padding: 50px 40px;
        }
        
        h1,h2,h3,h4,h5,h6{
            &:first-child{
                margin-top: 0;
                color: $glnpPurple;
                font-size: 34px;
                font-weight: 300;

                @media(max-width: map-get($mq-breakpoints, 'tablet')){
                    font-size: 30px;
                }
            }
        }

        p{
            font-size: 20px;
            font-weight: 400;
            color: $lightpurple;

            &:first-of-type{
                margin-top: 0;
            }
        }

        ul{
            list-style-type: none;
            padding-left: 0;
            
            li{
                font-size: 18px;
                font-weight: 400;
                color: $glnpPurple;
                margin-bottom: 20px;

                a{
                    color: $glnpTeal;
                    margin-bottom: 20px;
                    background-repeat: no-repeat;
                    background-size: 100% 0em;
                    background-position: 0 100%;
                    transition: all 0.2s ease-in;
                    background-image: linear-gradient(120deg, $glnpTeal 0%, $glnpTeal 100%);
                    border-bottom: 2px solid $glnpTeal;
                    
                    &:hover{
                        background-size: 100% 100%;
                        color: $white;
                    }
                }
            }
        }
    }

    @media(max-width: map-get($mq-breakpoints, 'tablet')){
        .layout-wrapper{
            padding-bottom: 60px;
            padding-top: 60px;
            
            .inner-content{
                padding: 30px 20px;
            }
        }
    }
}


.slidernav{
    height: 60px;
    width: 60px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - (60px / 2) );
    z-index: 100;

    .icon{
        color: $glnpPurple;
        width: 28px;
        height: 28px;
        transition: color .2s linear;
    }

    &.next{
        right: 0;
    }

    &.prev{
        left: 0;
    }

    &:hover{
        cursor: pointer;

        .icon{
            color: $glnpTeal;
        }
    }
}