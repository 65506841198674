.first-content#service-content{
	margin-bottom: 105px;

	@media (max-width: map-get($mq-breakpoints, 'tablet')){
		margin-bottom: 60px;
	}
}

#service-references{
	.layout-wrapper{
		padding-top: 0;
		padding-bottom: 40px;
	}
}
