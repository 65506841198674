#our-partners{
	height: 460px;

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		height: 320px;
	}

	.banner-content{
		.title{
			padding-bottom: 20px;
		}
	}

}

.partners{

	padding-top: 90px;
	padding-bottom: 130px;

	.title{
		color: $glnpPurple;
		font-weight: 300;
		font-size: 40px;
		text-align: center;
		margin: 0;
		margin-bottom: 40px;
	}

	.content{
		max-width: 870px;
		margin: 0 auto;
		margin-bottom: 40px;
		text-align: center;

		p:first-child{
			color: $glnpPurple;
			font-size: 22px;
			margin-top: 0;
		}
	}

	.partners-wrapper{
		display: grid;
		grid-template-columns: repeat(auto-fill, 207px);
		grid-column-gap: 18px;
		grid-row-gap: 25px;
		justify-content: center;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: flex;
			flex-wrap: wrap;

			.partner{
				margin-bottom: 25px;
				margin-right: 18px;
			}
		}

		.partner{
			
			img{
				border: 1px solid #EAF0F3;
				margin-bottom: 20px;
			}

			.name{
				font-size: 18px;
				color: $lightpurple;
				margin: 0;
				font-weight: 400;
			}
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		padding-top: 40px;

		.title{
			font-size: 30px;
			margin-bottom: 25px;
		}

		.content{
			text-align: left;

			p:first-child{
				font-size: 19px;
			}
		}

		.partners-wrapper{
			grid-template-columns: repeat(auto-fill, 135px);
			grid-column-gap: 10px;
			grid-row-gap: 20px;

			.partner{
				img{
					width: 100%;
					margin-bottom: 10px;
				}

				.name{
					font-size: 14px;
				}
			}
		}
	}

}