.easyadmin{
	// Reset to default easyadmin styles
	color: #333;
	font-family: sans-serif;
	font-size: .875rem;
	font-weight: 400;
	line-height: 1.2501;
	min-width: 320px;
	overflow-x: hidden;

	input{
		font-size: .875rem;
	}

	a{
		color: #5c70d6;
		text-decoration: none;
		background-color: transparent;

		&:hover{
			color: #99a6e6;
		}
	}

	.sidebar-menu{
		li{
			
			a{
				color: #333;
			}

			&.active{
				a{
					color: #5c70d6;
				}
			}

		}
	}

	.sidebar-menu a, 
	.datagrid thead a{
		color: var(--text-color-dark);

		&:hover{
			color: #99a6e6;
		}
	}

	.btn-primary{
		background-color: var(--color-primary);
	    color: var(--text-on-primary);

		&:hover{
			color: var(--text-on-primary);
		}
	}
	// END RESET
	
	// Custom styling
	.field-content_block_form {
		background-color: #f8fafc;
		border: 2px solid grey;
		margin-bottom: 10px;
		padding: 15px 20px;
		transition: transform .1s linear;

		&.ui-sortable-helper{
			transform: scale(.90);
		}
	}

	.contentBlock-draggable{
		> .field-collection{
			> legend{
				display: none;
			}

			> .form-widget{
				margin: 0 auto;
				flex: 0 0 80%;
			}
		}
	}

	.form-group label,
	.form-group legend.col-form-label{
		text-align: left;
	}

	.gallery,
	.files,
	.fragments{

		// border: 2px solid #ababab;
		margin-top: 20px;
		margin-bottom: 20px;

		.field-gallery_image,
		.field-knowledge_hub_file{
			border: 2px solid #ababab;
			float: left;
			max-width: 200px;
			width: 100%;
			height: 325px;
			transition: transform .1s ease-in-out;
			margin: 0 15px 15px 0;

			> .col-form-label{
				display: none;
			}

			.form-group{
				padding: 0 0 5px;
			}

			&.ui-sortable-helper{
				transform: scale(.90);
			}
		}

		.field-knowledge_hub_file{
			max-height: 250px;
			padding:0 20px 15px 20px;
		}

		.field-checkbox{
			> .form-widget{
				margin-left: 0;
			}
		}

		[id$="_delete"]{
			margin-top: 10px;
		}

		.field-collection{
			>.form-widget{
				flex: 0 0 80%;
			}
		}

		[id$="service_quickLinks"]{
			input[type="text"]{
				max-width: 70%;
			}
		}
	}

	.files{
		.easyadmin-thumbnail{
			display: none;
		}

		.form-control-label{
			flex: auto;
		}
	}

	.form-help{
		display: block;
	}

	.form-group{
		justify-content: center;
	}

	.form-control-label,
	.form-group legend.col-form-label{
		flex: 10% 0 0;
	}


	.form-group.field-collection-action{
		margin-left: 0;
	}

	.field-collection-item-row{
		.form-control-label{
			flex: none;
			width: 100%;
		}
	}

	.field-checkbox{
		justify-content: flex-start;
		.form-widget{
			margin-left: 17%;
		}
	}

	.field-reference_link,
	.field-quick_link,
	.field-page_fragment,
	.field-slider_item{
		border: 2px solid #ababab;
		padding: 20px 0 20px 20px;
		margin-bottom: 20px;
		position: relative;
		transition: transform .1s ease-in-out;

		&.ui-sortable-helper{
			transform: scale(.90);
		}

		&::after{
			content: "\f0b2";
			font-family: 'Font Awesome 5 Free';
			color: #818181;
			position: absolute;
			top: 0;
			right: 0;
			font-weight: 600;
			font-size: 22px;
			padding-right: 5px;
			padding-top: 5px;
			cursor: pointer;
		}
	}

	.field-gallery_image{
		padding-left: 20px;
		margin-right: 10px;
		margin-bottom: 10px;
		padding-top: 30px;
		position: relative;

		&::after{
			content: "\f0b2";
			font-family: 'Font Awesome 5 Free';
			color: #818181;
			position: absolute;
			top: 0;
			right: 0;
			font-weight: 600;
			font-size: 22px;
			padding-right: 5px;
			padding-top: 5px;
			cursor: pointer;
		}
	}

	.field-page_fragment{
		&:after{
			display: none;
		}

		label, 
		legend.col-form-label{
			flex: 0%;
		}
	}

	input[id$="_imageFile_delete"],
	label[for$="_imageFile_delete"]{
		display: none;
	}

	.ui-state-highlight{
		height: 71px;
		background-color: rgba($glnpTeal, .3);
	}

	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
	   .wrapper{
	   	display: flex;
	   	flex-direction: row;
	   	flex-wrap: wrap;
	   	justify-content: center;

		   	.main-header{
		   		width: 100%;
		   		margin: 0 auto 40px;
		   	}

		   	.content-wrapper{
		   		max-width: 1205px;
		   		width: 100%;
		   		background-color: $white;

		   		.content-body{
		   			form{
		   				background: #f8fafc;
		   			}

		   			.nav-tabs .nav-link.active{
		   				border-right: 1px solid #e3e7ee;
		   				border-top: 1px solid #e3e7ee;
		   				border-left: 1px solid #e3e7ee;
		   			}

		   			.nav.nav-tabs{
		   				border-bottom: 1px solid #e3e7ee;
		   			}
		   		}
		   	}

		   	.main-sidebar{
				max-width: 200px;
				width: 100%;
				margin-right: 20px;
		   	}

	   	}

		.form-group{
			justify-content: flex-start;

			.form-widget{
				margin-left: 0;
			}

			.form-control-label.required::after{
				color: red;
			}
		}

		.toggle{
			pointer-events: none;
			input[type="checkbox"]:checked{
				&+span{
					background-color: green;
				}
			}

			input[type="checkbox"]{
				&+span{
					background-color: red;
				}
			}
		}

		.content-header{
			.global-actions{
				max-width: 410px;
				width: 100%;
			}
		}

		.modal-content{
			background-color: $white;
			border: 2px solid #e3e7ed;
		}
	}

	&#easyadmin-list-Partner{
		.ui-sortable{
			.ui-sortable-handle{
				.actions::after{
					content: "\f0b2";
					font-family: 'Font Awesome 5 Free';
					color: #818181;
					font-weight: 600;
					font-size: 16px;
					cursor: grab;
					margin-left: 15px;
				}
			}

			.ui-sortable-helper{
				cursor: grabbing;
			}
		}
	}
}

.slider-preview{
	&.featherlight-inner{
		width: 1600px;
	}
	&.easyadmin-lightbox:hover{
		cursor: auto;
	}
	
	.inner-wrap-slider{
		position: relative;
	}

	.items{
		opacity: 0;
		visibility: hidden;
		transition: opacity 1s ease; 
		// width: 1600px;


		&.slick-initialized{
			opacity: 1;
			visibility: visible;
		}
		.item{
			position: relative;
			max-width: 1600px;
			height: 600px;
			.banner-content{
				position: absolute;
				bottom: 0;
			}
		}
	}
}