.call-to-action-bar{
	background-color: $glnpTeal;

	.layout-wrapper{
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: 840px;
		padding: 55px 20px;

		.title{
			color: $white;
			font-size: 35px;
			font-weight: 300;
			margin: 0;
			margin-right: 30px;

			strong{
				font-weight: 700;
			}
		}

		.button{
			max-width: 180px;
		}

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			flex-direction: column;
			max-width: 100%;
			justify-content: center;
			text-align: center;

			.title{
				margin-right: 0;
				font-size: 30px;
			}

			.button{
				max-width: 100%;
				margin: 30px auto 0;
				font-size: 18px;
			}
		}
	}

}