.home-hero{
	position: relative;

	.item{
		height: 600px;
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
		.item{
			height: 320px;			
		}
	}

	.inner-wrap-slider{
		position: relative;
	}

	.slider-content{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
			top: 250px;
			bottom: auto;

			.banner-content{
				padding: 0;
				background-color: rgba($glnpPurple, 1);

				.inner-content{
					padding: 20px;
				}

				.title{
					font-size: 26px;
				}
				.subtitle{
					font-size: 18px;
				}

				.link{
					font-size: 16px;
				}
			}
		}

		.slick-list{
			max-width: 580px;
			width: 100%;
		}
	}
}

.intro{
	
	background-image: linear-gradient( 
		180deg,
		rgba($lavender, .5) 0%,
		rgba($lavender, .5) 65%,
		rgba($white, .5) 65%,
		rgba($white, .5) 100%,
	);

	@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
		background-image: linear-gradient( 
			180deg,
			rgba($lavender, .5) 0%,
			rgba($lavender, .5) 70%,
			rgba($white, .5) 70%,
			rgba($white, .5) 100%,
		);
	}

	.layout-wrapper{
		padding-top: 100px;
		padding-bottom: 80px;
		
		@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
			padding-top: 200px;
		}
		
		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			padding-top: 300px;
			padding-bottom: 40px;
		}
	}

	.title,
	.content{
		max-width: 970px;
		margin: 0 auto;
		text-align: center;

		p{
			margin: 0;
		}
	}

	.title{
		font-weight: 300;
		font-size: 40px;
		margin-bottom: 40px;
		color: $glnpPurple;
	}

	.content{
		font-size: 24px;
		margin-bottom: 80px;
	}

	.cta-block-wrapper{
		display: flex;
		flex-direction: row;

		.cta-block{
			padding: 50px;
			background-color: $white;
			border: 2px solid $lavender;
			max-width: 394px;
			width: 100%;
			

			a{
				display: flex;
				flex-direction: column;	
				color: $glnpPurple;
			}

			.title{
				margin: 0;
				font-size: 30px;
				color: $glnpPurple;
				text-align: left;
				margin-bottom: 25px;
			}

			.content{
				text-align: left;
				margin: 0;
				margin-bottom: 30px;
				font-size: 20px;
				p{
					margin: 0;
				}
			}

			.cta-block-icon{
				margin-bottom: 35px;

				.icon{
					width: 50px;
					height: 50px;
					color: $glnpTeal;
				}
			}

			@media ( max-width: map-get($mq-breakpoints, 'tablet') ){
				padding: 35px 30px;
				max-width: 240px;

				.title{
					font-size: 26px;
					margin-bottom: 20px;
				}

				.cta-block-icon{
					margin-bottom: 15px;
					.icon{
						width: 30px;
						height: 30px;
					}
				}

				.content{
					font-size: 16px;
					margin-bottom: 25px;
				}

				a{
					.icon{
						width: 24px;
						height: 24px;
					}
				}
			}

		}
	}

	@media(max-width: 860px){
		.layout-wrapper{
			padding-right: 0;
		}
		
		.title,
		.content{
			padding-right: 20px;
		}

		.cta-block-wrapper{
			overflow-x: scroll;
		}
		.cta-block{
			flex-shrink: 0;
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		.title,
		.content{
			text-align: left;
		}
	}

}

.home.generic-content{
	background-color: $white;

	.layout-wrapper{
		padding-top: 35px;
		padding-bottom: 130px;
	}

	.title{
		font-size: 30px;
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		.image{
			img{
				width: 100%;
			}
		}

		.layout-wrapper{
			padding-bottom: 80px;
		}
	}
}


.home.partners{
	padding-bottom: 100px;

	.layout-wrapper{
		.title,
		.content{
			text-align: left
		}

		.content{
			margin-left: 0;
		}
	}
	.partner-slider{
		position: relative;
		
		.partner{
			width: auto;

			border: 1px solid $lavender;
		}
		.slidernav{
			background-color: $glnpPurple;


			.icon{
				color: $white;
			}

			@media(max-width: map-get($mq-breakpoints, 'phone-l')){
				display: none;
			}
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'tablet')){
		padding-top: 70px;
		padding-bottom: 35px;
    }
}

.home.news{
	.layout-wrapper{
		padding-top: 35px;
		padding-bottom: 120px;

		@media(max-width: map-get($mq-breakpoints, 'tablet')){
			padding-top: 35px;
			padding-bottom: 50px;
		}
	
		.title-wrapper{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 50px;

			.title{
				font-size: 40px;
				font-weight: 300;
				color: $glnpPurple;
				margin: 0;
			}

			a{
				background-image: linear-gradient(120deg, $glnpTeal 0%, $glnpTeal 100%);
				color: $glnpTeal;
				border-bottom: 2px solid $glnpTeal;
				display: block;
				font-size: 18px;
				font-weight: 700;

				&:hover{
					color: $white;
				}
			}

			@media(max-width: map-get($mq-breakpoints, 'tablet')){
				flex-direction: column;
				align-items: flex-start;

				.title{
					margin-bottom: 20px;
				}
			}
		}

		.news-wrapper{
			display: grid;
			grid-template-columns: repeat(auto-fill, 360px);
			grid-column-gap: 50px;
			grid-row-gap: 30px;
			justify-content: center;


			@media(max-width: map-get($mq-breakpoints, 'tablet')){
				// grid-template-columns: repeat(auto-fill, 260px);
				overflow-x: scroll;
				overflow-y: hidden;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;


				.article-tease{
					flex-shrink: 0;
					margin-right: 20px;
				}
			}
		}
	
	}
}