.login-block {
    &.block {
        padding: 40px 0;
    }
}

.modal {
    .fos {
        max-width: 421px;
    }
}

.fos {
    margin: 0 auto;
    text-align: center;

    &__title {
        font-size: 22px
;        font-weight: $font-weight--bold;
        margin-bottom: 20px;
    }

    &__error {
        color: $text-color;
        font-weight: $font-weight--bold;
        margin-bottom: 20px;
    }

    &__instructions {
        margin-bottom: 30px;

        .fos__note {
            color: $text-color;
            font-size: 14px;
            font-weight: $font-weight--bold;
            margin-top: 20px;
        }
    }

    .form {
        text-align: center;

        &__row {
            justify-content: center;
        }

        &__label {
            flex-wrap: wrap;
        }

        &__label__text {
            margin-bottom: 4px;
            margin-right: 0;
            margin-top: 0;
            text-align: center;
            width: auto;
        }

        &__button {
            margin: 0 auto;
            max-width: 210px;
            @include button--size-default;
            @include button--skin-default;
        }

        &__reset {
            color: $text-color;
            display: block;
            font-size: 14px;
            font-weight: $font-weight--bold;
            margin-top: 20px;
        }

        &__error,
        &__hint {
            text-align: center;
        }
    }
}

.fos-login {
}

.fos-request {
    border-top: 2px solid $tjs-green;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding-top: 30px;
    text-align: center;

    .layout-wrapper {
        display: flex;
        flex-direction: column;
    }

    .button {
        align-self: center;
    }
}

// Responsive styles

@include mq($until: phone-l) {
    .fos {
        &__title {
            font-size: 21px;
        }
    }

}
